@font-face {
font-family: 'lumaIcons';
src: url(/_next/static/media/ed57cce41376b05c-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: 'lumaIcons Fallback';src: local("Arial");ascent-override: 93.75%;descent-override: 6.25%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_1a0993 {font-family: 'lumaIcons', 'lumaIcons Fallback'
}

@font-face {
font-family: 'cerebriSans';
src: url(/_next/static/media/d14667b6961242d4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: 'cerebriSans';
src: url(/_next/static/media/78485fce9a3bc450-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'cerebriSans';
src: url(/_next/static/media/39378d62bb8dc96b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'cerebriSans';
src: url(/_next/static/media/fe46c2674ccc081e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: 'cerebriSans';
src: url(/_next/static/media/69ef5ef9c0559eea-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
}

@font-face {
font-family: 'cerebriSans';
src: url(/_next/static/media/f027811d2c8f1e42-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
}@font-face {font-family: 'cerebriSans Fallback';src: local("Arial");ascent-override: 95.45%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 101.65%
}.__className_432e15 {font-family: 'cerebriSans', 'cerebriSans Fallback'
}

